import http from '../clients/Axios'

export default class LaporanSalesmanService {
  async getLaporanPenjualan(start_date, end_date, salesman_id) {
    const res = await http.get(
      `/report/salesman/sales?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[salesman_id]=${salesman_id}`
    )
    return res
  }

  async exportLaporanPenjualan(start_date, end_date, salesman_id) {
    return http({
      url: `/report/salesman/sales/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[salesman_id]=${salesman_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async getLaporanKomisi(start_date, end_date, salesman_id) {
    const res = await http.get(
      `/report/salesman/insentive?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[salesman_id]=${salesman_id}`
    )
    return res
  }

  async exportLaporanKomisi(start_date, end_date, salesman_id) {
    return http({
      url: `/report/salesman/insentive/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[salesman_id]=${salesman_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
