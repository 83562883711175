<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanSalesmanService from '@/services/LaporanSalesmanService'
import SalesmanService from '@/services/SalesmanService'
import { useToast } from 'primevue/usetoast'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanSalesmanService()
const salesmanService = new SalesmanService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
let isLoading = ref(false)
let form = reactive({
  date_from: null,
  date_to: null,
  salesman: null,
})
let submitted = ref(false)
let list_salesman = ref([])

const rules = {
  date_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await salesmanService.get('?sort=nama')
  list_salesman.value = res.data.data
})

function search(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  // loadData()
  onExport()
}
/*
function loadData() {
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  const salesmanId = form.salesman
  const page = options.page
  const perPage = options.rows

  isLoading.value = true
  laporanService
    .getLaporanKomisi(page, perPage, startDate, endDate, salesmanId)
    .then((res) => {
      items.value = res.data.data
      isLoading.value = false
    })
    .catch((err) => {
      errorHandler(err, 'Data Sales Order', toast)
    })
    .finally(() => (isLoading.value = false))
}
*/

function onExport() {
  isLoadingExport.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  let salesmanId = 0
  if (form.salesman) salesmanId = form.salesman

  isLoading.value = true
  laporanService
    .exportLaporanKomisi(startDate, endDate, salesmanId)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-komisi-salesman.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Komisi Salesman', toast)
    })
    .finally(() => ((isLoadingExport.value = false), (isLoading.value = false)))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}
/*
function onPage(e) {
  e.page = e.page + 1
  options = e
  loadData()
}
*/

function resetForm() {
  form.date_from = null
  form.date_to = null
  form.salesman = null
}
</script>

<template>
  <fx-report-container
    title="Laporan Komisi Salesman"
    :loading="isLoadingExport"
    @toggle="onToggle"
  >
    <template #content>
      <!--
      <DataTable :loading="isLoading" @page="onPage($event)">
        <template #header>
        </template>
      </DataTable>
      -->
      <form @submit.prevent="search(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-3">
            <Dropdown
              v-model="form.salesman"
              :options="list_salesman"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Salesman"
              class="w-full"
              :show-clear="true"
            />
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button type="submit" icon="pi pi-file-pdf" label="Export" />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
    <!-- <template #content>
      <DataTable
        :value="items"
        responsiveLayout="scroll"
        scrollable
        row-hover
        class="p-datatable-sm"
      >
        <template #header>
          <form @submit.prevent="onRequestData(!v$.$invalid)">
            <div class="formgrid grid">
              <div class="field col-12">
                <div class="font-bold">Filter</div>
              </div>
              <div class="field col-12 md:col-3">
                <Dropdown
                  v-model="v$.salesman.$model"
                  :options="list_salesman"
                  :class="{
                    'p-invalid': v$.salesman.$invalid && submitted,
                  }"
                  optionValue="id"
                  optionLabel="nama"
                  placeholder="Pilih Salesman"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.salesman.$invalid && submitted) ||
                    v$.salesman.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.salesman.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-2">
                <Calendar
                  v-model="v$.date_from.$model"
                  dateFormat="dd/mm/yy"
                  :class="{
                    'p-invalid': v$.date_from.$invalid && submitted,
                  }"
                  placeholder="Tanggal (dari)"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.date_from.$invalid && submitted) ||
                    v$.date_from.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.date_from.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-2">
                <Calendar
                  v-model="v$.date_to.$model"
                  dateFormat="dd/mm/yy"
                  :class="{
                    'p-invalid': v$.date_to.$invalid && submitted,
                  }"
                  placeholder="Tanggal (s/d)"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.date_to.$invalid && submitted) ||
                    v$.date_to.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.date_to.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <Button
                  :loading="isLoading"
                  type="submit"
                  icon="pi pi-search"
                  label="Cari"
                />
                <Button
                  type="reset"
                  icon="pi pi-refresh"
                  label="Reset"
                  class="p-button-outlined ml-2"
                />
              </div>
            </div>
          </form>
        </template>
        <template #empty>
          <div class="text-center">Tidak ada data.</div>
        </template>
        <template #loading>
          <div class="text-center">Memuat data, harap tunggu...</div>
        </template>
        <Column
          field="tanggal_bayar"
          header="Tgl. bayar"
          style="min-width: 150px"
        >
          <template #body="{ data }">
            {{ formatDate(data.tanggal_bayar) }}
          </template>
        </Column>
        <Column field="no_faktur" header="No Faktur" style="min-width: 150px" />
        <Column field="no_bukti" header="No Bukti" style="min-width: 140px" />
        <Column field="no_faktur" header="No Faktur" style="min-width: 120px" />
        <Column
          field="nama_pelanggan"
          header="Pelanggan"
          style="min-width: 250px"
        />
        <Column field="umur" header="Umur" style="min-width: 100px" />
        <Column
          field="jumlah_bayar"
          header="Jumlah bayar"
          style="min-width: 100"
        >
          <template #body="{ data }">
            {{ formatCurrency(data.jumlah_bayar) }}
          </template>
        </Column>
        <Column
          field="komisi_persen"
          header="% komisi"
          style="min-width: 100px"
        />
        <Column field="komisi" header="Komisi" style="min-width: 100px">
          <template #body="{ data }">
            {{ formatCurrency(data.komisi) }}
          </template>
        </Column>
      </DataTable>
    </template> -->
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
